<template>
  <v-row class="d-flex flex-column">
    <v-app-bar class="not-print d-flex justify-end" color="#eeeeee" app flat>
      <v-btn @click="$doc.print()" class="mr-5" color="primary">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>
    </v-app-bar>
    <div id="app" class="page" ref="document">
      <div class="text-center text-h4">
        {{ company.name }}
      </div>

      <div class="pl-3">
        Anamnese do {{ customerAlias() }}: <b>{{ customer.name }}</b>
      </div>
      <v-col>
        <template v-for="(question, index) in anamnese">
          <div :key="index">
            <template v-if="question.type == 'question'">
              <div v-if="question.answer">
                <span class="d-flex mb-2">
                  {{ question.question }}
                </span>
                <v-radio-group
                  class="mt-0"
                  v-if="question.question_type == 'single_option'"
                  column
                  v-model="question.answer"
                >
                  <v-radio
                    v-for="(option, index) in question.options"
                    :label="option"
                    readonly
                    color="primary"
                    :value="option"
                    :key="index"
                  />
                </v-radio-group>
                <div v-if="question.question_type == 'multiple_options'" column>
                  <v-checkbox
                    class="mt-0"
                    v-for="(option, index) in question.options"
                    v-model="question.answer"
                    :label="option"
                    readonly
                    color="primary"
                    :value="option"
                    :key="index"
                  />
                </div>

                <div v-if="question.question_type == 'open'">
                  <span v-text="question.answer" />
                </div>
                <hr class="my-4" />
              </div>
            </template>
            <!-- <template v-if="question.type == 'heading'">
              <div
                class="d-flex justify-space-between pr-4"
                style="width: 100%"
              >
                <h3 class="mt-6 mb-2">
                  {{ question.heading }}
                </h3>
              </div>
            </template> -->
          </div>
        </template>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      anamnese: [],
      customerAnamnese: [],
      customer: {},
      customerAlias,
    };
  },

  created() {
    this.$loading.start();
    this.$http
      .show("customer/customer", this.$route.params.id)
      .then((response) => {
        this.customer = response.customer;

        this.customerAnamnese = JSON.parse(
          this.customer.customer_anamnese.anamnese
        );

        this.mapAnameses();

        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },
  },

  methods: {
    mapAnameses() {
      this.anamnese = this.customerAnamnese.map((anamnese) => {
        if (anamnese.question_type == "open") {
          return {
            answer: anamnese.answer ?? null,
            question: anamnese.question,
            question_type: anamnese.question_type,
            type: anamnese.type,
          };
        }

        if (anamnese.question_type == "multiple_options") {
          return {
            answer: anamnese.answer ?? [],
            question: anamnese.question,
            question_type: anamnese.question_type,
            type: anamnese.type,
            options: anamnese.options,
          };
        }

        if (anamnese.question_type == "single_option") {
          return {
            answer: anamnese.answer ?? null,
            question: anamnese.question,
            question_type: anamnese.question_type,
            type: anamnese.type,
            options: anamnese.options,
          };
        }
        if (anamnese.type == "heading") {
          return {
            heading: anamnese.heading,
            type: anamnese.type,
          };
        }
      });
    },
  },
};
</script>
<style lang="sass" scoped>
#app
  font-family: 'Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50

.not-print
  display: flex
  justify-content: flex-center

.page
  background-color: #fff

@page
  size: A4
  margin: 0.5cm
  padding: 0.5cm

@media print
  .not-print
    display: none
    visibility: hidden
  .v-main
    padding: 0px 0px 0px 0px !important
    height: 100%
</style>